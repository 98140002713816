import {
  object
} from 'yup';
import getRules from 'assets/js/validRules';

const rules = (locale = 'en') => {
  const RULES = getRules(locale);

  return object({
    first_name: RULES.required,
    last_name: RULES.required,
    company_name: RULES.required,
    email: RULES.corporateEmail,
    phone: RULES.phone,
  });
};

export default rules;