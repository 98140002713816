import React, { useMemo } from "react";
import classnames from "classnames";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import useStaticImage from "hooks/graphql/useStaticImage";

import * as styles from "./success.module.scss";

const Success = ({ data }) => {
  const getStaticImage = useStaticImage();

  const image = useMemo(() => {
    return getImage(getStaticImage("common/success-media.png").childImageSharp);
  }, [getStaticImage]);

  return (
    <div className={classnames(styles.success)}>
      <h2 className={classnames(styles.title, "scr-h2")}>
        {data.inActionFormSuccessTitle}
      </h2>

      <p className={classnames(styles.subtitle, "scr-reg")}>
        {data.inActionFormSuccessSubtitle}
      </p>

      <div>
        <GatsbyImage image={image} alt={"success image"} objectFit="contain" />
      </div>
    </div>
  );
};

export default Success;
