import React, { useState, useMemo } from 'react';
import classnames from 'classnames';

import { SendForm } from 'api/forms';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import FormElem from './components/FormElem';
import Success from './components/Success';

import useOptions from 'hooks/graphql/useOptions';
import useStaticImage from 'hooks/graphql/useStaticImage';
import useDeviceState from 'hooks/useDeviceState';
import { DESKTOP_KEY } from 'constants/devices.js';
import { useWindowHeight } from '@react-hook/window-size';

import * as styles from './request-demo-form.module.scss';

const RequestDemoForm = ({ illustr = 'false' }) => {
  const [seccess, setSuccess] = useState(false);
  const options = useOptions();
  const windowHeight = useWindowHeight();
  const getStaticImage = useStaticImage();
  const deviceState = useDeviceState();

  const { inActionForm } = options.inActionForm;

  const {
    inActionFormTitle,
    inActionFormSubtitle,
    inActionFormSuccess,
  } = inActionForm;

  const leftIllustr = useMemo(() => {
    return getImage(
      getStaticImage('request/ping-pong-img.png').childImageSharp
    );
  }, [getStaticImage]);

  const rightIllustr = useMemo(() => {
    return getImage(getStaticImage('request/box-img.png').childImageSharp);
  }, [getStaticImage]);

  //TODO: Create custom event
  const createDemoSubmitEvent = () => {
    typeof window !== 'undefined' &&
      window.dataLayer !== 'undefined' &&
      window.dataLayer.push({ event: 'demo_submit' });
  };

  const handleSubmit = (values) => {
    SendForm(values, 'action_form');
    // console.info(values, 'action_form');

    setTimeout(() => {
      window.scrollBy(0, (windowHeight / 2) * -1);

      setSuccess(!seccess);
      createDemoSubmitEvent();
    }, 400);
  };

  return (
    <div className={styles.wrap}>
      {!seccess ? (
        <>
          {deviceState === DESKTOP_KEY && illustr === 'true' && (
            <>
              <div className={classnames(styles.leftIllustr, styles.illustr)}>
                <GatsbyImage
                  image={leftIllustr}
                  alt={'ping-pong'}
                  objectFit="contain"
                />
              </div>

              <div className={classnames(styles.rightIllustr, styles.illustr)}>
                <GatsbyImage
                  image={rightIllustr}
                  alt={'box'}
                  objectFit="contain"
                />
              </div>
            </>
          )}

          <h3 className="scr-h3 scr-txt-center">{inActionFormTitle}</h3>
          <p className="scr-txt-center">{inActionFormSubtitle}</p>

          <FormElem
            data={inActionForm}
            onSubmit={(values) => handleSubmit(values)}
          />
        </>
      ) : (
        <Success data={inActionFormSuccess} />
      )}
    </div>
  );
};

export default RequestDemoForm;
