import { useRef } from 'react';

import { useInView } from 'react-intersection-observer';

import observerConfig from 'constants/settings/intersectionConfig.js';

const useIntersection = (config = observerConfig) => {
  const scrollWrapper = useRef(null);
  const [inViewRefSec, inViewSec] = useInView({
    ...observerConfig,
    ...config,
  });

  return {
    scrollWrapper,
    inViewSec,
    inViewRefSec,
  };
};

export default useIntersection;
