import { useCallback, useMemo } from 'react';
import { useLocation } from '@reach/router';
import { navigate } from 'gatsby';

import useAllLocales from 'hooks/graphql/useAllLocales';

import { parse as parseQs } from 'query-string';

const useCurrentLocale = () => {
  const { search } = useLocation();
  const { defaultLanguage } = useAllLocales();

  const currentLocale = useMemo(
    () => parseQs(search)?.locale || defaultLanguage.slug,
    [defaultLanguage.slug, search]
  );

  const changeLocale = useCallback((locale) => {
    navigate(`?locale=${locale}`);
  }, []);

  return { currentLocale, changeLocale };
};

export default useCurrentLocale;
