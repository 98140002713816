import React, { useMemo } from "react";
import classnames from "classnames";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import * as styles from "./screver-in-action.module.scss";
import RequestDemoForm from "components/Forms/RequestDemoForm";
import useStaticImage from "hooks/graphql/useStaticImage";
import SvgElems from "./compoents/SvgElems";
import useIntersection from "hooks/useIntersection";

const ScreverInAction = ({ data }) => {
  const getStaticImage = useStaticImage();
  const { scrollWrapper, inViewSec, inViewRefSec } = useIntersection({
    threshold: 0.3,
  });

  const image = useMemo(
    () =>
      getImage(
        getStaticImage("employee-engagement/screver-in-action/bg-left.png")
          .childImageSharp
      ),
    [getStaticImage]
  );

  return (
    <section ref={scrollWrapper} className={classnames(styles.section)}>
      <div className="scr-wrap">
        <div
          className={classnames(styles.inner, {
            [styles.animate]: inViewSec,
          })}
        >
          <div ref={inViewRefSec} className={styles.left}>
            <GatsbyImage image={image} alt={""} />

            <div className={styles.left_svgs}>
              <SvgElems />
            </div>
          </div>

          <div className={styles.right}>
            <RequestDemoForm />
          </div>
        </div>
      </div>
    </section>
  );
};

export default ScreverInAction;
