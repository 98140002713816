import React, { useMemo } from "react";
import { useField } from "formik";
import classnames from "classnames";
import * as styles from "./checkbox.module.scss";
import Icon from "components/UI/Icon";

const Checkbox = ({ children, className, ...props }) => {
  const [field, meta] = useField({ ...props, type: "checkbox" });

  const statusCssClasses = useMemo(
    () => ({
      [styles.error]: meta.touched && meta.error,
    }),
    [meta]
  );

  return (
    <div className={classnames(styles.checkbox, className, statusCssClasses)}>
      <label className={styles.checkbox_cont}>
        <input type="checkbox" {...field} {...props} />
        <Icon
          className={styles.checkbox_icon}
          name={meta.value ? "checkbox-on" : "checkbox-off"}
        />
        <div className={styles.checkbox_label}>{children}</div>
      </label>

      {meta.touched && meta.error && (
        <div className={styles.checkbox_error}>
          {!props.errorMessage ? meta.error : props.errorMessage}
        </div>
      )}
    </div>
  );
};

export default Checkbox;
