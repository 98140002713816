import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import { CSSTransition } from 'react-transition-group';

import Input from 'components/UI/InputFormik';
import InputPhone from 'components/UI/InputPhoneFormik';
import Checkbox from 'components/UI/Checkbox';
import Button from 'components/UI/Button';
import TextAreaFormik from 'components/UI/TextAreaFormik';
import validationSchema from './validationSchema';
import convertToSlug from 'helpers/stringToSlug';

import * as styles from './form-elem.module.scss';

import { useLocation } from '@reach/router';

const FormElem = ({ data, onSubmit }) => {
  const [isActiveTextarea, setIsActiveTextarea] = useState(false);
  const location = useLocation();

  const {
    inActionFormNameInputLabel,
    inActionFormLastNameInputLabel,
    inActionFormCompanyNameInputLabel,
    inActionFormEmailInputLabel,
    inActionFormPhoneInputLabel,
    inActionFormAdditionalTitle,
    inActionFormButton,
    inActionFormAdditionalCheckboxes,
    inActionFormTextareaComment,
  } = data;

  const LABELS = {
    first_name: inActionFormNameInputLabel,
    last_name: inActionFormLastNameInputLabel,
    company_name: inActionFormCompanyNameInputLabel,
    email: inActionFormEmailInputLabel,
    phone: inActionFormPhoneInputLabel,
    satisfaction:
      inActionFormAdditionalCheckboxes[0].inActionFormAdditionalCheckboxesItem,
    engagement:
      inActionFormAdditionalCheckboxes[1].inActionFormAdditionalCheckboxesItem,
    other:
      inActionFormAdditionalCheckboxes[2].inActionFormAdditionalCheckboxesItem,
    comment: inActionFormTextareaComment,
  };

  const labels = LABELS;

  const handleSubmit = (values) => {
    // TODO:
    // const checkboxFields = ["engagement", "other", "satisfaction"];
    // const result = Object.entries(values)
    //   .filter(([key, value]) => !checkboxFields.includes(key) || value)
    //   .reduce((obj, [key, value]) => ({ ...obj, [key]: value }), {});

    if (onSubmit) onSubmit(values);
    // console.log(values);
  };

  return (
    <Formik
      initialValues={{
        first_name: '',
        last_name: '',
        company_name: '',
        email: '',
        phone: '',
        satisfaction: true,
        engagement: false,
        other: false,
        page_location: location.href,
      }}
      validationSchema={validationSchema()}
      onSubmit={(values, { setSubmitting }) => {
        setTimeout(() => {
          setSubmitting(false);
          handleSubmit(values);
        }, 400);
      }}
    >
      {({ values, setFieldValue, errors }) => {
        return (
          <Form className={styles.form} noValidate>
            <div className={styles.form_flex}>
              <Input
                name="first_name"
                type="text"
                label={labels.first_name}
                requiredField={true}
                className={styles.form_field}
              />
              <Input
                name="last_name"
                type="text"
                label={labels.last_name}
                requiredField={true}
                className={styles.form_field}
              />
            </div>

            <Input
              name="company_name"
              type="text"
              label={labels.company_name}
              requiredField={true}
              className={styles.form_field}
            />

            <Input
              name="email"
              type="text"
              label={labels.email}
              requiredField={true}
              className={styles.form_field}
            />

            <InputPhone
              name="phone"
              type="tel"
              label={labels.phone}
              requiredField={true}
              className={styles.form_field}
            />

            <input
              type="text"
              name="page_location"
              value={location.href}
              className={styles.hidden}
              readOnly
            />

            <p className="scr-txt-strong">{inActionFormAdditionalTitle}</p>
            <Checkbox
              name="satisfaction"
              className={styles.form_checkbox}
              id={`checkbox-scr-action-form-${convertToSlug(
                labels.satisfaction
              )}`}
            >
              {labels.satisfaction}
            </Checkbox>
            <Checkbox
              name="engagement"
              className={styles.form_checkbox}
              id={`checkbox-scr-action-form-${convertToSlug(
                labels.engagement
              )}`}
            >
              {labels.engagement}
            </Checkbox>

            <Checkbox
              name="other"
              className={styles.form_checkbox}
              onClick={() => setIsActiveTextarea(!isActiveTextarea)}
              id={`checkbox-scr-action-form-${convertToSlug(labels.other)}`}
            >
              {labels.other}
            </Checkbox>

            <CSSTransition
              in={isActiveTextarea}
              classNames="scr-css-transition--fade"
              timeout={{
                enter: 100,
                exit: 150,
              }}
              unmountOnExit
            >
              <TextAreaFormik
                name="comment"
                // placeholder={labels.comment_placeholder}
                label={labels.comment}
                requiredField={false}
                className={styles.form_textarea}
              />
            </CSSTransition>

            <Button
              type="submit"
              className={styles.form_submit}
              id="button-submit-scr-action-form"
            >
              {inActionFormButton}
            </Button>
          </Form>
        );
      }}
    </Formik>
  );
};

export default FormElem;
