import {
  useStaticQuery,
  graphql
} from 'gatsby';

const allLocales = graphql `
  query {
    allWp {
      nodes {
        languages {
          code
          homeUrl
          locale
          name
          slug
        }

        defaultLanguage {
          code
          homeUrl
          id
          locale
          name
          slug
        }
      }
    }
    
  }
`;

const useAllLocales = () => {
  const {
    allWp: {
      nodes
    },
  } = useStaticQuery(allLocales);

  return {
    languages: nodes[0].languages,
    defaultLanguage: nodes[0].defaultLanguage
  };
};

export default useAllLocales;
